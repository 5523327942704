<template>
    <div>
        <div class="form-row" v-if="!floorplansList.length">
            <div class="bg-red-100 p-4 text-xs w-full text-red-500 cursor-pointer" @click="createFloorplan">
                <icon name="info" class="h-6 w-6 inline mr-4"></icon>You do not have any floor plan added yet. <label class="font-bold underline">Create more here.</label>
            </div>
        </div>

        <div class="form-row" v-if="floorplansList.length && floorplansList.length < minFeatured">
            <div class="bg-red-100 p-4 text-xs w-full text-red-500 cursor-pointer" @click="createFloorplan">
                <icon name="info" class="h-6 w-6 inline mr-4"></icon>You have to add at least {{ minFeatured }} floor plans. <label class="font-bold underline">Create more here.</label>
            </div>
        </div>

        <div class="form-row">
            <div class="form-col" v-if="floorplansList.length >= 3">
                <label for="sectionTitle">section title</label>
                <text-input
                  id="sectionTitle"
                  v-model="sectionTitle"
                />
                <span v-if="hasError('sectionTitle')" class="form-error">{{ showError('sectionTitle') }}</span>

                <label for="description" class="mt-8">description</label>
                <text-input
                  id="description"
                  v-model="description"
                  multiline
                />
                <span v-if="hasError('description')" class="form-error">{{ showError('description') }}</span>

                <label for="buttonText" class="mt-8">button text</label>
                <text-input
                  id="buttonText"
                  v-model="buttonText"
                />

                <label class="mt-8">button link</label>
                <url-input v-model="buttonLink" :website-id="websiteId"></url-input>
            </div>
            <div class="form-col">
                <label>featured floor plans</label>
                <ul class="border-b border-gray-300 mb-4 pb-4 flex flex-wrap relative" v-if="featuredFloorplans.length > 0">
                    <transition-group name="list-complete" mode="in-out">
                    <li class="bg-highlight-200 border border-highlight-300 tracking-wide text-xs font-500 py-2 px-4 mt-3 mr-3 inline-block text-highlight-textSmall cursor-pointer flex items-center justify-between featured-item"
                        v-for="(featured, idx) in featuredFloorplans"
                        @click="remove(idx)"
                        :key="featured.key">
                        {{ featured.key }}
                        <span class="inline-block ml-4">
                            <icon name="close" class="w-2 h-2"></icon>
                        </span>
                    </li>
                    </transition-group>
                </ul>
                <ul v-else class="mt-4">
                    <li class="text-gray-800 font-frank font-400 text-sm border-b border-gray-300 mb-4 pb-4">please select at least {{ minFeatured }}, and up to {{ maxFeatured }}, floor plans from the list below</li>
                </ul>
                <span v-if="hasError('featuredFloorplans')" class="form-error">{{ showError('featuredFloorplans') }}</span>
                <ul v-if="featuredFloorplans.length === maxFeatured" class="mt-4">
                    <li class="text-blue-800 font-frank text-xs font-semibold mb-4 pb-4">
                        maximum of {{ maxFeatured }} floor plans are selected
                    </li>
                </ul>
                <!-- Full List of Floorplans -->
                <ul class="floorplans-list">
                    <li class="cursor-pointer" v-for="(item, idx) in sortedFloorplans"
                        :data-value="item.value"
                        @click="add(item, idx)"
                        :class="{'selected' : featuredFloorplans.find(plan => plan.key === item.key)}"
                        :key="item.key">
                        {{ item.key }}
                    </li>
                </ul>
                <!--
                <tags-input v-model="featuredFloorplans" :options="floorplansList"></tags-input>
                <span class="form-hint mb-4">Select at least {{ minFeatured }} and up to {{ maxFeatured }} floorplans to be featured in the carousel.</span>
                <span v-if="hasError('featuredFloorplans')" class="form-error">{{ showError('featuredFloorplans') }}</span>                 -->
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import { mapGetters} from "vuex";
    import Widget from "@/mixins/Widget";
    import Icon from "@/components/ui/Icon";
    import UrlInput from "@/components/cms/UrlInput";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import TextInput from "@/components/ui/TextInput";

    export default {
        mixins: [ ModalNavigation, Widget ],
        components: {ModalFooter, UrlInput, Icon, TextInput },
        emits: ['save-page-widget'],
        data: () => {
            return {
                sectionTitle: null,
                description: null,
                buttonText: null,
                buttonLink: null,
                featuredFloorplans: [],
                minFeatured: 3,
                maxFeatured: 9,
                websiteId: null,
                isProcessing: false
            }
        },
        computed: {
            ...mapGetters({
                floorplans: 'floorplans/getFloorplans'
            }),
            floorplansList() {
                let options = [];
                if(this.floorplans && this.floorplans.length) {
                    this.floorplans.forEach( item => {
                        options.push( { key: item.name, value: item.name });
                    })
                }
                return options;
            },
            sortedFloorplans: function() {
                let list = this.floorplansList
                return list.sort((a,b) => a.key < b.key ? -1 : 1)
            }
        },
        methods: {
            save() {
                if(this.validator.passes()) {
                    this.isProcessing = true;
                    let floorplans = [];
                    this.featuredFloorplans.forEach( item => floorplans.push(item.key));

                    this.$emit('save-page-widget', {
                        sectionTitle: this.sectionTitle,
                        description: this.description,
                        buttonText: this.buttonText,
                        buttonLink: this.buttonLink,
                        floorplans: JSON.stringify(floorplans)
                    });
                }
            },
            add(item){
                if( this.featuredFloorplans.length <= this.maxFeatured - 1 && !this.featuredFloorplans.find(plan => plan.key === item.key)) {
                    this.featuredFloorplans.push(item);
                }
            },
            remove(idx){
                this.featuredFloorplans.splice(idx, 1);
            },
            createFloorplan() {
                this.$router.push({ name: 'websites.floorplans.create', params: { id: this.websiteId }});
            },
        },
        validations: {
            'sectionTitle' : 'required',
            'description' : 'required',
            'featuredFloorplans' : 'required | min:minFeatured | max:maxFeatured'
        },
        created() {
            this.initValidator();
            this.websiteId = this.$route.params.websiteId;

            this.validator.setCustomErrorMessages({
                'featuredFloorplans.min' : 'You must select at least ' + this.minFeatured + ' floor plans.',
                'featuredFloorplans.max' : 'You must select at most ' + this.maxFeatured + ' floor plans.',
            })

            if(this.values.length) {

                let floorplans = JSON.parse(this.getValue('floorplans'));
                let featuredFloorplans = [];
                floorplans.forEach(item => {
                    featuredFloorplans.push({ key: item, value: item});
                });

                this.sectionTitle = this.getValue('sectionTitle');
                this.description = this.getValue('description');
                this.buttonText = this.getValue('buttonText');
                this.buttonLink = this.getValue('buttonLink');
                this.featuredFloorplans = featuredFloorplans;
            }
        }
    }
</script>
<style scoped>
    .floorplans-list{
        @apply grid overflow-auto;
        grid-template-columns: 1fr;
        max-height: 25vh;
        min-height: 200px;
        font-size: .875rem;
        line-height: 2;
    }
    .floorplans-list li{
        @apply whitespace-pre overflow-hidden py-4;
        max-width: 100%;
        text-overflow: ellipsis;
        line-height: 1;
    }
    .selected {
        @apply line-through;
        color: #ccc;
    }
    .featured-item {
    transition: all .35s;
    display: inline-block;
    }
    .list-complete-enter-from, .list-complete-leave-to{
    opacity: 0;
    transform: translateY(5px);
    }
    .list-complete-leave-active {
    position: absolute;
    white-space: pre;
    }

    .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
